<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
       
            <b-col cols="4">
              <b-form-group
                label="من أي رابط"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="FromLink"
                  rules=""
                  
                >
                <b-form-tags placeholder="ادخل رابط "  v-model="addCourseForm.link" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="إعادة توجيه ل"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Redirect To"
                  rules=""
                >
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.to"
                    label="name"
                    :options="List"
                    :reduce="(val) => val.id"
                    @input="emptyLink"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="addCourseForm.to == 2">
              <b-form-group
              label="الدورة"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  <v-select
                    @input="getLink(addCourseForm.course)"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.course"
                    label="name"
                    :options="coursesList"
                    :reduce="(val) => val"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="الرابط" label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Link"
                  rules="required"
                >
                  <b-form-input v-model="addCourseForm.to_link" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
       
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
         حفظ
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
 
    <!--/ form -->
    <redircation-list ref="table" />
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
  BFormTags

} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import RedircationList from "./RedircationList.vue"

import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
  setup() {

    const coursesList = ref([]);
    const List = ref([
      {
        id: 1,
        name: "link",
      },
      {
        id: 2,
        name: "course",
      },
    ]);
    const addCourseForm = reactive({
      link: "",
      to: "",
      to_link: "",
    });
    const table=ref()

    store.dispatch("courses/getAllCoursesList").then((response) => {
      console.log("coursesList", response.data);
      response.data.forEach(el=>{
        if(el.online==1){
          coursesList.value.push({
            id:el.id,
           name:`${el.name} اونلاين`,
            link_id:el.link_id
          })
        }
        else{
          coursesList.value.push({
            id:el.id,
            name:el.name,
            link_id:el.link_id
          })
        }
      })
    
    });
    const getLink = (item) => {
      console.log("ud", item);
      addCourseForm.to_link = item.link_id;
     
      console.log("addCourseForm", addCourseForm);
    };
    const emptyLink=()=>{
    addCourseForm.to_link=''
    addCourseForm.course=''
   }
  
    const addCourseFormvalidate = ref();

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          if (addCourseForm.link.some(el => el === addCourseForm.to_link)) {
              Vue.swal({
                title: '',
                text: ' إعادة التوجيه هذه موجودة بالفعل من المصدر إلى الوجهة',
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
          formData.append("from_link", addCourseForm.link);
          formData.append("to_link", addCourseForm.to_link);
    
          store
            .dispatch("setting/addLinkRederication", formData)
            .then((response) => {
              addCourseForm.link=''
              addCourseForm.to=''
              addCourseForm.to_link=''
              addCourseForm.course=''
              table.value.refetchData()
              Vue.swal({
                title: "تم الاضافة بنجاح ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "حسنا",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
          }
          console.log("alt_header_image", addCourseForm);
        }
      });
    };
    const removeSpace = (target) => {
      addCourseForm.link= target
      .toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''""=]/g, ``)
        .replace(/[!?%$^~*#()+]/g, ``)
    };
  
    return {
    
      removeSpace,
      addCourseForm,
    
      addCourseFormvalidate,
      coursesList,
      emptyLink,
      List,
      getLink,
      save,
      table,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    BFormTags
,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
RedircationList,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
  .mt-50.col-12 {
    margin-bottom: 12px 0px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
